import styles from "./branches.module.css";
import Control from "../../../components/control";
import Radio from "../../../components/radio";
import { ReactComponent as Dollar } from "../../../assets/icons/$.svg";
import { ReactComponent as Percent } from "../../../assets/icons/Percent.svg";

export default function Branch({
  branch,
  setDefault,
  defaultBranch,
  defaultChecked,
  prevData,
}) {
  function invertEditable(value) {
    setDefault(value);
  }

  return (
    <div className={styles.branchContainer}>
      <div className={styles.branchTopSection}>
        <div className={styles.branchName}>{branch.Branch_Name}</div>
        <Radio
          name="Default_Branch"
          id={`default-branch-${branch._id}`}
          value={branch._id}
          required
          defaultChecked={defaultChecked}
          onChange={invertEditable}
          label={
            <label
              className={styles.defaultBranch}
              htmlFor={`default-branch-${branch._id}`}
            >
              Default Branch
            </label>
          }
        />
      </div>
      <div className={styles.branchInputsContainer}>
        <Control
          id={`branch-${branch._id}-price`}
          name={`Branch_Cost_Price-${branch._id}`}
          fullWidth
          label="Price"
          type="number"
          initialValue={prevData ? prevData[`Branch_Cost_Price`] : undefined}
          endIcon={<Percent />}
          editable={defaultBranch !== branch._id}
        />
        <Control
          id={`branch-${branch._id}-selling-price`}
          name={`Branch_Selling_Price-${branch._id}`}
          fullWidth
          label="Selling Price"
          type="number"
          initialValue={prevData ? prevData[`Branch_Selling_Price`] : undefined}
          endIcon={<Percent />}
          editable={defaultBranch !== branch._id}
        />
        <Control
          id={`branch-${branch._id}-delivery`}
          name={`Branch_Delivery_Cost-${branch._id}`}
          fullWidth
          label="Delivery Cost"
          type="number"
          initialValue={prevData ? prevData[`Branch_Delivery_Cost`] : undefined}
          endIcon={<Dollar />}
        />
        <Control
          id={`branch-${branch._id}-edt`}
          name={`Branch_EDT-${branch._id}`}
          fullWidth
          label="EDT"
          type="number"
          initialValue={prevData ? prevData[`Branch_EDT`] : undefined}
          endIcon={<span className={styles.daysLabel}>days</span>}
        />
      </div>
    </div>
  );
}
