import React from "react";
import Control from "../control";
import { debounce } from "lodash";
import styles from "./autocomplete.module.css";

export default function GoogleAutocomplete({
  id,
  label,
  fullWidth,
  name,
  required,
  initialValue,
  autocompleteService,
  callback,
  smallFont,
}) {
  const [search, setSearch] = React.useState(initialValue || "");
  const [suggestions, setSuggestions] = React.useState([]);
  const [open, setOpen] = React.useState(true);
  const inputRef = React.useRef(null);

  React.useEffect(() => {
    function closeDropdown(e) {
      const evtTarget = e.target;
      if (evtTarget !== inputRef.current) {
        setOpen(false);
      }
    }
    document.addEventListener("click", closeDropdown);
    return () => document.removeEventListener("click", closeDropdown);
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearch = React.useCallback(
    debounce((newValue) => {
      autocompleteService.current?.getPlacePredictions(
        {
          input: newValue,
          locationBias: "IP_BIAS",
        },
        (predictions, status) => {
          if (
            status !== window.google.maps.places.PlacesServiceStatus.OK ||
            !predictions
          ) {
            return;
          }
          setSuggestions(
            predictions.map((prediction) => ({
              id: prediction.place_id,
              name: prediction.structured_formatting.main_text.toUpperCase(),
              address: prediction.structured_formatting.secondary_text,
            })),
          );
        },
      );
    }, 200),
    [],
  );

  function handleSearchChange(newValue) {
    setSearch(newValue);
    setOpen(true);
    debouncedSearch(newValue);
  }

  function handleOptionClick(suggestion, e) {
    setSearch(suggestion.address || suggestion.name);
    setOpen(false);
    e.currentTarget.blur();
    const service = new window.google.maps.places.PlacesService(
      document.createElement("div"),
    );
    service.getDetails(
      {
        placeId: suggestion.id,
      },
      (result, status) => {
        if (status !== window.google.maps.places.PlacesServiceStatus.OK) {
          alert("Something went wrong, please select location again");
        } else {
          const loc = result?.geometry?.location;
          if (!loc) {
            alert(
              "Cannot process location, please try some other nearby place",
            );
          }
          const coords = `${loc.lng()},${loc.lat()}`;
          inputRef.current.setAttribute("data-coords", coords);
          if (callback) {
            callback(coords);
          }
        }
      },
    );
  }

  return (
    <div className={`${styles.container} ${open ? styles.active : ""}`}>
      <Control
        id={id}
        label={label}
        name={name}
        required={required}
        fullWidth={fullWidth}
        value={search}
        onChange={handleSearchChange}
        ref={inputRef}
        smallFont={smallFont}
      />
      <ul className={styles.list}>
        {suggestions.map((suggestion) => (
          <li key={suggestion.id}>
            <input
              type="radio"
              name={name}
              id={`${id}@${suggestion.address || suggestion.name}@${
                suggestion.id
              }`}
              required={required}
              value={`${id}@${suggestion.address || suggestion.name}@${
                suggestion.id
              }`}
              onClick={(e) => handleOptionClick(suggestion, e)}
              autoComplete="off"
            />
            <label
              htmlFor={`${id}@${suggestion.address || suggestion.name}@${
                suggestion.id
              }`}
            >
              <div className={styles.name}>{suggestion.name}</div>
              <div className={styles.address}>{suggestion.address}</div>
            </label>
          </li>
        ))}
      </ul>
    </div>
  );
}
