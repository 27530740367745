export default function Palette({
  code,
  width = 256,
  height = 256,
  borderRadius = 8,
}) {
  return (
    <div style={{ backgroundColor: `#${code}`, width, height, borderRadius }} />
  );
}
