import React from "react";
import styles from "./timeline.module.css";
import dayjs from "dayjs";

export default function Timeline({ events }) {
  const [expanded, setExpanded] = React.useState(events.length <= 5);

  const data = expanded ? events : events.slice(0, 5);

  return (
    <div>
      <div className={styles.heading}>Timeline</div>
      <table className={styles.table}>
        <thead>
          <tr>
            <th align="left">Entity</th>
            <th align="left">Message</th>
            <th align="left">Time</th>
            <th align="left">User</th>
          </tr>
        </thead>
        <tbody>
          {data.map((record) => (
            <tr key={record._id}>
              <td>{record.Event_Entity}</td>
              <td>{record.Event_Comment}</td>
              <td>{dayjs(record.Event_Date).format("YYYY/MM/DD hh:mmA")}</td>
              <td>{record.Event_User?.Customer.Customer_Name || ""}</td>
            </tr>
          ))}
        </tbody>
      </table>
      {!expanded && (
        <div role="button" className={styles.seeAll}>
          <span onClick={() => setExpanded(true)}>See All&nbsp;</span>
          <svg
            width="15"
            height="8"
            viewBox="0 0 15 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7.49787 8C7.39734 8.00012 7.29779 7.98103 7.20492 7.94381C7.11204 7.90658 7.02769 7.85197 6.95671 7.7831L0.222267 1.26691C0.0795714 1.12786 -0.000353176 0.939768 1.17326e-06 0.743819C0.000355522 0.547871 0.0809599 0.360046 0.224157 0.221489C0.367355 0.082932 0.56147 0.0049398 0.763982 0.00459694C0.966493 0.00425407 1.16089 0.0815885 1.30459 0.21966L7.49787 6.21223L13.6916 0.218729C13.7626 0.14957 13.847 0.0946491 13.9399 0.0571148C14.0329 0.0195806 14.1326 0.000171674 14.2333 1.13373e-06C14.3341 -0.000169407 14.4338 0.0189018 14.5269 0.056121C14.62 0.0933403 14.7046 0.147975 14.7758 0.216893C14.8471 0.285811 14.9035 0.367656 14.942 0.457734C14.9805 0.547812 15.0002 0.644351 15 0.741815C14.9998 0.839279 14.9798 0.935751 14.941 1.0257C14.9022 1.11565 14.8454 1.1973 14.7739 1.26597L8.03951 7.78217C7.96854 7.85126 7.88415 7.90608 7.79119 7.94347C7.69822 7.98085 7.59854 8.00006 7.49787 8Z"
              fill="var(--primary-color)"
            />
          </svg>
        </div>
      )}
    </div>
  );
}
