import axios from "axios";
import { convertToBase64 } from "./helpers";
import { TOKEN } from "./constants";
import { json } from "react-router-dom";

export async function createPostRequest(url, body) {
  let headers;
  const token = localStorage.getItem(TOKEN);
  if (token) {
    headers = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
  }
  try {
    const response = await axios.post(url, body, headers);
    const data = response.data;
    return data;
  } catch (err) {
    if (err.response) {
      if (err.response.status === 401) {
        localStorage.removeItem(TOKEN);
        throw json({
          message: "Session expired",
        });
      } else if (err.response.status >= 400) {
        throw json({
          message: err.response.data.errorMessage,
        });
      }
    }
  }
}

export async function createGetRequest(url) {
  let headers;
  const token = localStorage.getItem(TOKEN);
  if (token) {
    headers = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
  }
  try {
    const response = await axios.get(url, headers);
    const data = response.data;
    return data;
  } catch (err) {
    if (err.response) {
      if (err.response.status === 401) {
        localStorage.removeItem(TOKEN);
        throw json({
          message: "Session expired",
        });
      } else if (err.response.status >= 400) {
        throw json(
          {
            message: err.response.data.errorMessage,
          },
          { status: err.status },
        );
      }
    }
  }
}

export async function uploadImage(image, convert = true) {
  let base64Image = image;
  if (convert) {
    base64Image = await convertToBase64(image);
  }
  const url = `${process.env.REACT_APP_BASE_URL}/api/image/create`;
  const postResponse = await createPostRequest(url, {
    imageContent: base64Image,
    imageName: image.filename,
  });
  return postResponse.data;
}
