import { Outlet, useLoaderData, useNavigate } from "react-router-dom";
import Table from "../components/table";
import useChildRoute from "../hooks/useChildRoute";
import PageHeader from "../components/page-header";

export default function Categories() {
  const { categories } = useLoaderData();
  const navigate = useNavigate();
  const categoryRows =
    categories?.map((category) => [
      {
        value: `${category._id}?image=${encodeURIComponent(
          category.Category_Image,
        )}&name=${encodeURIComponent(category.Category_Name)}`,
      },
      { value: `#${category.Category_ID}` },
      { value: category.Category_Name },
      { value: category.Category_Metaname },
      {
        value: (
          <img
            src={`${process.env.REACT_APP_IMAGE_PREFIX}/${category.Category_Image}`}
            style={{ borderRadius: "50%", width: 100, height: 100 }}
            alt="preview"
          />
        ),
        align: "left",
      },
    ]) || [];
  const isChildRoute = useChildRoute("/user/categories");

  function handleClick(link) {
    navigate(link);
  }

  return (
    <>
      {!isChildRoute && <PageHeader text="Categories" />}
      <Outlet />
      <Table
        headers={[
          { name: "ID", width: "25%" },
          { name: "Name", width: "25%" },
          { name: "Metaname", width: "25%" },
          { name: "IMG", width: "25%", align: "left" },
        ]}
        rows={categoryRows}
        bodyStyles={
          isChildRoute
            ? { height: "calc(100vh - 795px)" }
            : { height: "calc(100vh - 350px)" }
        }
        onRowClick={handleClick}
      />
    </>
  );
}
