import Control from "../../../components/control";
import styles from "./variant.module.css";
import { ReactComponent as Dollar } from "../../../assets/icons/$.svg";
import VariantColor from "../variant-color";
import * as React from "react";
import Radio from "../../../components/radio";
import Slider from "../../../components/slider";
import Dropdown from "../../../components/dropdown";

export default function Variant({
  count,
  colours,
  prevData,
  onDelete,
  id,
  categories,
}) {
  const [isStocked, setIsStocked] = React.useState(
    (prevData && prevData[`Variant_Stock`] === "Stocked") || false,
  );
  const sizeTypeToUnits = {
    [`Height#${id}`]: [
      { label: "MM", value: `MM#${id}` },
      { label: "CM", value: `CM#${id}` },
      { label: "M", value: `M#${id}` },
    ],
    [`Width#${id}`]: [
      { label: "MM", value: `MM#${id}` },
      { label: "CM", value: `CM#${id}` },
      { label: "M", value: `M#${id}` },
    ],
    [`Length#${id}`]: [
      { label: "MM", value: `MM#${id}` },
      { label: "CM", value: `CM#${id}` },
      { label: "M", value: `M#${id}` },
    ],
    [`Weight#${id}`]: [
      { label: "G", value: `G#${id}` },
      { label: "KG", value: `KG#${id}` },
    ],
    [`Size#${id}`]: [
      { label: "Sq. M", value: `M2#${id}` },
      { label: "Cub. M", value: `M3#${id}` },
    ],
  };

  const [isNonStandard, setIsNonStandard] = React.useState(
    (prevData && prevData[`Variant_Size_Type`] === "Non Standard") || false,
  );
  const [unitDropdownOptions, setUnitDropdownOptions] = React.useState(
    prevData && prevData[`Variant_Size_Type_Units`]
      ? sizeTypeToUnits[prevData[`Variant_Size_Type_Option`]]
      : [],
  );

  function handleSizeDropdownChange(newValue) {
    setUnitDropdownOptions(sizeTypeToUnits[newValue]);
  }

  function handleSizeChange(newValue) {
    setIsNonStandard(newValue === "Non Standard");
  }

  function handleChange(newValue) {
    setIsStocked(newValue === "Stocked");
  }

  return (
    <div className={styles.container}>
      <div className={styles.flex}>
        <div className={styles.mainText}>Variant #{count}</div>
        {onDelete ? (
          <div role="button" className={styles.delete} onClick={onDelete}>
            Delete
          </div>
        ) : (
          <Slider
            name={`Variant_Status-${id}`}
            defaultChecked={prevData?.Variant_Status === "Active"}
          />
        )}
      </div>

      <div className={styles.inputContainer}>
        <Control
          id={`variant-size-${id}`}
          name={`Variant_Size-${id}`}
          required
          fullWidth
          label="Size"
          initialValue={prevData ? prevData["Variant_Size"] : undefined}
        />
        <Control
          id={`variant-cost-price-${id}`}
          name={`Variant_Cost_Price-${id}`}
          required
          fullWidth
          label="Cost Price"
          endIcon={<Dollar />}
          type="number"
          step="any"
          initialValue={prevData ? prevData["Variant_Cost_Price"] : undefined}
        />
        <Control
          id={`variant-sell-price-${id}`}
          name={`Variant_Selling_Price-${id}`}
          required
          fullWidth
          label="Selling Price"
          endIcon={<Dollar />}
          type="number"
          step="any"
          initialValue={
            prevData ? prevData["Variant_Selling_Price"] : undefined
          }
        />
        <div className={styles.radioContainer}>
          <Radio
            id={`variant-size-standard-${id}`}
            name={`Variant_Size_Type-${id}`}
            required
            fullWidth
            label="Standard"
            value="Standard"
            onChange={handleSizeChange}
            defaultChecked={!isNonStandard}
            labelClassname={styles.radioLabel}
          />
          <div className={styles.spacer} />
          <Radio
            id={`variant-size-not-standard-${id}`}
            name={`Variant_Size_Type-${id}`}
            required
            fullWidth
            label="Non Standard"
            value="Non Standard"
            onChange={handleSizeChange}
            defaultChecked={isNonStandard}
            labelClassname={styles.radioLabel}
          />
        </div>
        <div className={styles.radioContainer}>
          <Radio
            id={`variant-stock-${id}`}
            name={`Variant_Stock-${id}`}
            required
            fullWidth
            label="Stocked"
            value="Stocked"
            onChange={handleChange}
            defaultChecked={isStocked}
            labelClassname={styles.radioLabel}
          />
          <div className={styles.spacer} />
          <Radio
            id={`variant-not-stock-${id}`}
            name={`Variant_Stock-${id}`}
            required
            fullWidth
            label="Not Stocked"
            value="Not Stocked"
            onChange={handleChange}
            defaultChecked={!isStocked}
            labelClassname={styles.radioLabel}
          />
        </div>
      </div>
      <div className={styles.portalsContainer}>
        <div>
          {isNonStandard && (
            <div className={styles.optionsContainer}>
              <Dropdown
                fullWidth
                id={`variant-size-type-${id}`}
                label="Size Type"
                name={`Variant_Size_Type_Option-${id}`}
                options={[
                  {
                    label: "Height",
                    value: `Height#${id}`,
                  },
                  {
                    label: "Width",
                    value: `Width#${id}`,
                  },
                  {
                    label: "Size",
                    value: `Size#${id}`,
                  },
                  {
                    label: "Weight",
                    value: `Weight#${id}`,
                  },
                  {
                    label: "Length",
                    value: `Length#${id}`,
                  },
                ]}
                placeholder="Select"
                required
                onChange={handleSizeDropdownChange}
                value={
                  prevData ? prevData[`Variant_Size_Type_Option`] : undefined
                }
              />
              <Dropdown
                fullWidth
                options={unitDropdownOptions}
                id={`variant-size-type-unit-${id}`}
                label="Unit Measure"
                name={`Variant_Size_Type_Units-${id}`}
                placeholder="Select"
                required
                value={
                  prevData ? prevData[`Variant_Size_Type_Units`] : undefined
                }
              />
              <Control
                label="Minimum Size"
                required
                fullWidth
                id={`variant-size-minimum-${id}`}
                name={`Variant_Size_Min-${id}`}
                type="number"
                initialValue={
                  prevData ? prevData[`Variant_Size_Min`] : undefined
                }
              />
              <Control
                label="Maximum Size"
                required
                fullWidth
                id={`variant-size-maximum-${id}`}
                name={`Variant_Size_Max-${id}`}
                type="number"
                initialValue={
                  prevData ? prevData[`Variant_Size_Max`] : undefined
                }
              />
            </div>
          )}
        </div>
        <div>
          {isStocked && (
            <Control
              label="Inventory Location"
              id={`variant-inventory-location-${id}`}
              name={`Variant_Warehouse_Location-${id}`}
              fullWidth
              required
              initialValue={
                prevData ? prevData[`Variant_Warehouse_Location`] : undefined
              }
            />
          )}
        </div>
      </div>
      <VariantColor
        colours={colours}
        id={id}
        prevData={prevData}
        categories={categories}
      />
    </div>
  );
}
