import { Form } from "react-router-dom";
import Control from "../../../components/control";
import styles from "./color-details.module.css";
import Palette from "../../../components/palette";
import React from "react";
import PrimaryButton from "../../../components/primary-button";
import BreadCrums from "../../../components/breadcrums";
import Dropdown from "../../../components/dropdown";
import CustomLink from "../../../components/custom-link";
import { ReactComponent as IconAdd } from "../../../assets/icons/Add.svg";

export default function ColorDetails({
  heading = "Create Colour",
  submitting,
  code: initialCode,
  name,
  category,
  showSubmit,
  onFormChange,
  categoryOptions,
}) {
  const [code, setCode] = React.useState(initialCode || "f57153");
  return (
    <div className={styles.container}>
      <BreadCrums
        segments={[
          { label: "Colours", link: ".." },
          { label: heading, link: "." },
        ]}
      />
      <div className={styles.headingContainer}>
        <div className={styles.heading}>{heading}</div>
        <CustomLink
          text="Create Item"
          icon={<IconAdd />}
          href="/user/colours/add"
        />
      </div>
      <Form className={styles.form} method="post" onChange={onFormChange}>
        <div className={styles.flex}>
          <Palette code={code} width={282} height={282} />
          <div className={styles.inputContainer}>
            <Control
              label="Colour Name"
              id="colour-name"
              name="Colour_Name"
              required
              initialValue={name}
            />
            <div className={styles.spacing} />
            <Control
              label="Colour Code"
              value={code}
              onChange={setCode}
              id="colour-code"
              name="Colour_Code"
              required
            />
          </div>
          <div className={styles.inputContainer}>
            <Dropdown
              name="Colour_Category"
              id="colour-category"
              label="Category"
              required
              options={categoryOptions}
              placeholder="Select Category"
              value={category}
            />
            <div className={styles.submitContainer}>
              {(showSubmit === undefined || showSubmit) && (
                <PrimaryButton
                  label={showSubmit ? "Save Changes" : heading}
                  type="submit"
                  loading={submitting}
                />
              )}
            </div>
          </div>
        </div>
      </Form>
    </div>
  );
}
