import { Link } from "react-router-dom";
import styles from "./table.module.css";
import { ReactComponent as IconNext } from "../../assets/icons/next.svg";
import { ReactComponent as IconPrev } from "../../assets/icons/prev.svg";

/**
 * IMP: First column in rows (i.e. for each row) needs to be identifier
 * with which to call function. In case no click feature is required the
 * it can be the first data value
 */
export default function Table({
  headers = [],
  rows = [],
  bodyStyles = {},
  onRowClick,
  nextLink,
  prevLink,
  content,
  currentPage,
  totalPages,
}) {
  const width = `${100 / headers.length}%`;

  function handleClick(id) {
    if (onRowClick) {
      onRowClick(id);
    }
  }

  return (
    <div className={styles.listContainer}>
      <table className={styles.table}>
        <colgroup>
          {headers.map((header) => (
            <col style={{ width: header.width || width }} key={header.name} />
          ))}
        </colgroup>
        <thead>
          <tr>
            {headers.map((header) => (
              <th align={header.align || "left"} key={header.name}>
                {header.name}
              </th>
            ))}
          </tr>
        </thead>
      </table>
      <div className={styles.tableBody} style={bodyStyles}>
        <table>
          <colgroup>
            {headers.map((header) => (
              <col style={{ width: header.width || width }} key={header.name} />
            ))}
          </colgroup>

          <tbody>
            {rows.length > 0 &&
              rows.map((row, index) => (
                <tr
                  key={index.toString()}
                  onClick={() => handleClick(row[0].value)}
                >
                  {row
                    .slice(rows[0].length > headers.length ? 1 : 0)
                    .map((entry, index) => (
                      <td
                        align={entry.align || "left"}
                        key={index.toString()}
                        valign={entry.vAlign || "middle"}
                      >
                        {entry.value}
                      </td>
                    ))}
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      {content ? (
        content
      ) : (
        <div className={styles.paginationContainer}>
          {prevLink && (
            <Link to={prevLink} className={styles.pageOption}>
              <IconPrev />
            </Link>
          )}
          {currentPage && (
            <span className={styles.pageNumber}>
              Page {currentPage} of {totalPages}
            </span>
          )}
          {nextLink && (
            <Link to={nextLink} className={styles.pageOption}>
              <IconNext />
            </Link>
          )}
        </div>
      )}
    </div>
  );
}
