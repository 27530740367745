import { redirect, useLoaderData, useNavigation } from "react-router-dom";
import ColorDetails from "../color-details";
import { createPostRequest } from "../../../lib/network";

export async function action({ request }) {
  const formData = await request.formData();
  const data = Object.fromEntries(formData);
  data["Colour_Code"] = data["Colour_Code"].toUpperCase();
  const url = `${process.env.REACT_APP_BASE_URL}/api/colour/create`;
  await createPostRequest(url, data);
  return redirect("..");
}

export default function CreateColor() {
  const { state } = useNavigation();
  const { colourCategories } = useLoaderData();

  return (
    <ColorDetails
      submitting={state === "submitting"}
      categoryOptions={
        colourCategories?.colourCategories.map((cat) => ({
          label: cat.Colour_Category_Name,
          value: cat._id,
        })) || []
      }
    />
  );
}
