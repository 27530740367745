import React from "react";
import styles from "./details.module.css";

export default function DropdownButton({ update, disabled }) {
  const [open, setOpen] = React.useState(false);

  return (
    <div className={styles.dropdownContainer}>
      <div className={styles.buttonContainer}>
        <button
          className={`${styles.button} ${open ? styles.buttonOpen : ""}`}
          onClick={() => {
            update("Settled");
            setOpen(false);
          }}
          disabled={disabled}
        >
          Mark as Settled
        </button>
        <div
          className={`${styles.icon} ${open ? styles.iconInverted : ""}`}
          onClick={() => {
            if (!disabled) setOpen((curr) => !curr);
          }}
          data-disabled={disabled}
        >
          <svg
            width="33"
            height="18"
            viewBox="0 0 33 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={open ? styles.invert : styles.regular}
          >
            <path
              d="M16.4931 17.592C16.2841 17.5923 16.0771 17.5512 15.8841 17.4713C15.691 17.3913 15.5156 17.274 15.3681 17.126L1.36807 3.12599C1.07142 2.82726 0.905272 2.42314 0.906008 2.00214C0.906745 1.58115 1.07431 1.17761 1.372 0.87992C1.66969 0.582232 2.07323 0.414666 2.49422 0.413929C2.91522 0.413193 3.31934 0.579345 3.61807 0.87599L16.4931 13.751L29.3691 0.87399C29.5166 0.725403 29.692 0.607406 29.8853 0.526764C30.0786 0.446122 30.2858 0.404422 30.4952 0.404055C30.7046 0.403689 30.912 0.444663 31.1056 0.524628C31.2991 0.604594 31.4749 0.721977 31.623 0.870046C31.7711 1.01812 31.8885 1.19396 31.9684 1.38749C32.0484 1.58102 32.0894 1.78844 32.089 1.99784C32.0886 2.20724 32.0469 2.41451 31.9663 2.60776C31.8857 2.80101 31.7677 2.97644 31.6191 3.12399L17.6191 17.124C17.4715 17.2724 17.2961 17.3902 17.1028 17.4705C16.9096 17.5508 16.7024 17.5921 16.4931 17.592Z"
              fill="#f57153"
            />
          </svg>
        </div>
      </div>
      <div
        className={`${styles.otherOption} ${
          open ? styles.otherOptionOpen : ""
        }`}
        role="button"
        onClick={() => {
          update("Void");
          setOpen(false);
        }}
      >
        Void
      </div>
    </div>
  );
}
