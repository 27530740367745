import * as React from "react";
import PrimaryButton from "../../../components/primary-button";
import Branch from "./branch";
import styles from "./branches.module.css";

export default function Branches({
  branches,
  onNext,
  prevData,
  prevDefaultBranch,
}) {
  const [defaultBranch, setDefaultBranch] = React.useState(prevDefaultBranch);

  React.useEffect(() => {
    if (branches.length > 0 && !prevDefaultBranch) {
      setDefaultBranch(branches[0]._id);
    }
  }, [branches, prevDefaultBranch]);

  return (
    <>
      {branches.map((branch, index) => (
        <Branch
          key={branch._id}
          branch={branch}
          defaultChecked={
            prevDefaultBranch ? branch._id === prevDefaultBranch : index === 0
          }
          defaultBranch={defaultBranch}
          setDefault={setDefaultBranch}
          prevData={prevData ? prevData[branch._id] : undefined}
        />
      ))}
      {branches.length > 0 && (
        <div className={styles.nextContainer}>
          <PrimaryButton label="Next" type="button" onClick={onNext} />
        </div>
      )}
    </>
  );
}
