import { BaseEdge, getStraightPath } from "reactflow";
import React from "react";
import { ColourContext } from "./utils";

export default function DoubleEdge({ id, sourceX, sourceY, targetX, targetY }) {
  const direction = React.useContext(ColourContext);
  const [e1, e2] = getEdgeEnds({
    x1: sourceX,
    y1: sourceY,
    x2: targetX,
    y2: targetY,
  });

  const [p1] = getStraightPath(e1.outer ? e1 : e2);
  const [p2] = getStraightPath(e1.outer ? e2 : e1);

  return (
    <>
      <BaseEdge
        id={id}
        path={p1}
        style={{
          stroke: direction === 1 ? "#276cF1" : "black",
          strokeDasharray: direction === 1 ? undefined : "5,5",
          strokeWidth: direction === 1 ? 3 : 2,
        }}
      />
      <BaseEdge
        id={id}
        path={p2}
        style={{
          stroke: direction === 0 ? "#276cF1" : "black",
          strokeDasharray: direction === 0 ? undefined : "5,5",
          strokeWidth: direction === 0 ? 3 : 2,
        }}
      />
    </>
  );
}

function getEdgeEnds({ x1, y1, x2, y2 }) {
  const slope = -(y2 - y1) / (x2 - x1);
  const pSlope = -1 / slope;
  const [p1, p2] = pointAtDist(pSlope, 3, { x: x1, y: -y1 });
  const [p3, p4] = pointAtDist(pSlope, 3, { x: x2, y: -y2 });
  const plus = { sourceX: p1.x, sourceY: -p1.y, targetX: p3.x, targetY: -p3.y };
  const minus = {
    sourceX: p2.x,
    sourceY: -p2.y,
    targetX: p4.x,
    targetY: -p4.y,
  };
  let outer = "plus";
  if (slope === 0 && x2 > x1) {
    outer = "minus";
  } else if (!isFinite(slope) && y2 > y1) {
    outer = "minus";
  } else if ((x2 < x1 && y2 > y1) || (x2 > x1 && y2 > y1)) {
    outer = "minus";
  }
  return [
    { ...plus, outer: outer === "plus" },
    { ...minus, outer: outer === "minus" },
  ];
}

function pointAtDist(slope, dist, { x, y }) {
  if (slope === 0) {
    return [
      { x: x + dist, y },
      { x: x - dist, y },
    ];
  } else if (!isFinite(slope)) {
    return [
      { x, y: y + dist },
      { x, y: y - dist },
    ];
  } else {
    const dx = dist / Math.sqrt(1 + slope * slope);
    const dy = slope * dx;
    return [
      { x: x + dx, y: y + dy },
      { x: x - dx, y: y - dy },
    ];
  }
}
