import React from "react";
import {
  Form,
  redirect,
  useFetcher,
  useLoaderData,
  useNavigation,
} from "react-router-dom";
import { generateGetLoader, getInvoiceStatus } from "../../../lib/helpers";
import BreadCrums from "../../../components/breadcrums";
import Control from "../../../components/control";
import styles from "./order-details.module.css";
import Table from "../../../components/table";
import Dropdown from "../../../components/dropdown";
import dayjs from "dayjs";
import PrimaryButton from "../../../components/primary-button";
import OrderEdit from "./order-edit";
import GoogleAutocomplete from "../../../components/google-autocomplete";
import { useLoadScript } from "@react-google-maps/api";
import { createPostRequest } from "../../../lib/network";
import Modal from "../../../components/modal";
import { ReactComponent as Close } from "../../../assets/icons/close.svg";
import DatePicker from "./date-picker";

const libs = ["places"];

export async function loader({ params }) {
  const response = await generateGetLoader(
    [
      `${process.env.REACT_APP_BASE_URL}/api/order/admin/getOne/${params.id}`,
      `${process.env.REACT_APP_BASE_URL}/api/supplier/getAll`,
    ],
    ["order", "suppliers"],
  )();
  return response;
}

export async function action() {
  return redirect("../orders");
}

export default function OrderDetails() {
  const { order, suppliers } = useLoaderData();
  const { isLoaded } = useLoadScript({
    libraries: libs,
    googleMapsApiKey: process.env.REACT_APP_MAPS_API_KEY,
  });
  const autoCompleteService = React.useRef();
  const { state } = useNavigation();
  const formRef = React.useRef();
  const submitRef = React.useRef();

  const [updated, setUpdated] = React.useState(false);
  const [editedRow, setEditedRow] = React.useState("");
  const [orderDetails, setOrderDetails] = React.useState({
    items: order.Order_Items.map((item) => ({
      supplier: item.Branch_Item_Object.Branch_Item.Item_Supplier.Supplier_Name,
      branch: item.Branch_Item_Object.Branch_ID.Branch_Name,
      name: `${item.Branch_Item_Object.Branch_Item.Item_Name} - ${item.Variant_Object.Variant_Size}`,
      cutting: item.Cutting_List,
      price: `$${item.Variant_Object.Variant_Selling_Price}`,
      colour: item.Variant_Object.Variant_Colour?.Colour_Name || "",
      totalPrice: `$${item.Total_Item_Price}`,
      itemID: item.Branch_Item_Object.Branch_Item?.Item_ID,
      variantID: item.Variant_Object.Variant_ID,
      unit: item.Variant_Object.Variant_Size_Type_Units,
      quantity: item.Quantity,
      raw: item,
    })),
    location: order.Order_Location_Coords,
    date: dayjs(order.Order_Delivery_Date).format("YYYY-MM-DD"),
  });
  const [showModal, setShowModal] = React.useState(false);
  const fetcher = useFetcher();

  const supplierProducts = order.Order_Items.reduce((acc, curr) => {
    if (!acc[curr.Branch_Item_Object.Branch_Item.Item_Supplier._id]) {
      acc[curr.Branch_Item_Object.Branch_Item.Item_Supplier._id] = {
        name: curr.Branch_Item_Object.Branch_Item.Item_Supplier.Supplier_Name,
        count: 0,
      };
    }
    acc[curr.Branch_Item_Object.Branch_Item.Item_Supplier._id].count++;
    return acc;
  }, {});

  const poSuppliers = order.Purchase_Orders.reduce((acc, curr) => {
    acc[curr.PurchaseOrder_Supplier._id] = `PO #${curr.PurchaseOrder_Number}`;
    return acc;
  }, {});
  let subtotal = 0;
  const orderRows = orderDetails.items.map((item, index) => {
    subtotal += parseFloat(item.totalPrice.slice(1));
    return [
      {
        value: index.toString(),
      },
      {
        value: item.supplier,
        vAlign: "top",
      },
      {
        value: item.branch,
        vAlign: "top",
      },
      {
        value: item.name,
        vAlign: "top",
      },
      {
        value:
          !item.cutting || item.cutting.length === 0 ? (
            ""
          ) : (
            <div className={styles.list}>
              {item.cutting.map((record, index) => (
                <div key={index.toString()}>
                  {record.Size} {item.unit}
                </div>
              ))}
            </div>
          ),
      },
      {
        value: item.colour,
        vAlign: "top",
      },
      {
        value:
          !item.cutting || item.cutting.length === 0 ? (
            item.quantity
          ) : (
            <div className={styles.list}>
              {item.cutting.map((record, index) => (
                <div key={index.toString()}>{record.Quantity}</div>
              ))}
            </div>
          ),
        align: "center",
      },
      {
        value: item.price,
        vAlign: "top",
        align: "center",
      },
      {
        value: item.totalPrice,
        vAlign: "top",
        align: "center",
      },
    ];
  });

  const { text, colour } = getInvoiceStatus(order.Order_Invoice);
  let inputClassName;
  // eslint-disable-next-line default-case
  switch (colour) {
    case "var(--success)":
      inputClassName = styles.success;
      break;
    case "var(--error)":
      inputClassName = styles.error;
      break;
    case "var(--info)":
      inputClassName = styles.info;
  }

  function onRowClick(id) {
    setEditedRow(id);
    setTimeout(() => {
      document.getElementById("edit").scrollIntoView({
        behavior: "smooth",
      });
    }, 0);
  }

  async function addVersion() {
    submitRef.current.setAttribute("disabled", "true");
    const formData = new FormData(formRef.current);
    const splits = orderDetails.date.split("-");
    let format = "YYYY-MM-DD";
    if (splits[0].length !== 4) {
      format = "DD-MM-YYYY";
    }
    const body = {
      orderID: order._id,
      orderUpdateObject: {
        location: orderDetails.location,
        address: formData.get("Delivery_Address"),
        checkoutType: formData.get("Order_Delivery_Type"),
        items: orderDetails.items.map((item) => ({
          item: item.itemID,
          variant: item.variantID,
          qty:
            item.quantity && !isNaN(item.quantity) ? item.quantity : undefined,
          cuttingList:
            item.cutting && item.cutting.length > 0
              ? item.cutting.map((record) => ({
                  size: record.Size,
                  qty: record.Quantity,
                }))
              : undefined,
        })),
        deliveryOrPickupDate: dayjs(orderDetails.date, format).toISOString(),
      },
    };
    await createPostRequest(
      `${process.env.REACT_APP_BASE_URL}/api/order/admin/addVersion`,
      body,
    );
    submitRef.current.removeAttribute("disabled");
    setShowModal(false);
    formRef.current.requestSubmit();
  }

  React.useEffect(() => {
    if (isLoaded) {
      autoCompleteService.current =
        new window.google.maps.places.AutocompleteService();
    }
  }, [isLoaded]);

  return (
    <div>
      <BreadCrums
        segments={[
          { label: "Orders", link: "../orders" },
          { label: `Order #${order.Order_Number}`, link: "." },
        ]}
      />
      <Form ref={formRef} method="POST">
        {updated && (
          <section className={styles.buttonsContainer}>
            <PrimaryButton
              label="Save Changes"
              type="button"
              onClick={() => setShowModal(true)}
            />
          </section>
        )}
        <section className={styles.container}>
          <div className={styles.row}>
            <div className={styles.column}>
              <Control
                label="Customer Name"
                id="customer-name"
                name="Customer_Name"
                editable={false}
                fullWidth
                initialValue={
                  order.Order_Customer.Customer.Customer_Business_Name ||
                  order.Order_Customer.Customer.Customer_Name
                }
              />
              <Control
                label="Order Date"
                id="order-date"
                name="Order_Date"
                editable={false}
                fullWidth
                initialValue={dayjs(order.Order_Date).format("DD/MM/YYYY")}
              />
            </div>
            <div className={styles.column}>
              <Dropdown
                name="Order_Delivery_Type"
                id="order-delivery-type"
                label="Delivery"
                required
                fullWidth
                options={[
                  { value: "Delivery", label: "To Site" },
                  { value: "Pickup", label: "Pick Up" },
                ]}
                value={order.Order_Delivery_Type}
                onChange={() => setUpdated(true)}
              />
              <Control
                label="Status"
                id="status"
                name="Status"
                editable={false}
                fullWidth
                initialValue={text}
                inputClassName={inputClassName}
              />
            </div>
            <div className={styles.column}>
              <GoogleAutocomplete
                label="Address"
                id="delivery-address"
                name="Delivery_Address"
                fullWidth
                initialValue={order.Order_Delivery_Address}
                smallFont
                callback={(loc) => {
                  setOrderDetails((curr) => ({ ...curr, location: loc }));
                  setUpdated(true);
                }}
                autocompleteService={autoCompleteService}
              />
              <div className={styles.summary}>
                <div
                  className={`${styles.summaryHeader} ${styles.suppliersHeader}`}
                >
                  Order Suppliers
                </div>
                <div className={styles.summaryText}>
                  {Object.entries(supplierProducts).map(
                    ([id, { name, count }]) => (
                      <div className={styles.supplierOrderRow} key={id}>
                        <div>
                          {name}({count} items)
                        </div>
                        {poSuppliers[id] ? (
                          <div
                            className={`${styles.poButton} ${styles.poCreatedButton}`}
                          >
                            {poSuppliers[id]}
                          </div>
                        ) : (
                          <button
                            className={styles.poButton}
                            disabled={fetcher.state === "submitting"}
                            onClick={() => {
                              fetcher.submit(
                                { orderID: order._id, supplierID: id },
                                {
                                  method: "POST",
                                  action: "../purchase-orders/add",
                                },
                              );
                            }}
                          >
                            Create PO
                          </button>
                        )}
                      </div>
                    ),
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className={styles.tableContainer}>
          <Table
            headers={[
              { name: "Supplier", width: "12.3%" },
              { name: "Branch", width: "12%" },
              { name: "Item", width: "19%" },
              { name: "Size", width: "12.4%" },
              { name: "Colour", width: "14.7%" },
              { name: "QTY", width: "8.1%" },
              { name: "PPU", width: "6.5%" },
              { name: "Item Total", width: "15%" },
            ]}
            rows={orderRows}
            onRowClick={onRowClick}
            bodyStyles={{
              height: "auto",
            }}
            content={
              <OrderEdit
                order={order}
                items={orderDetails.items}
                editedRow={editedRow}
                suppliers={suppliers}
                key={editedRow}
                location={orderDetails.location}
                onDiscard={() => setEditedRow("")}
                onAdd={(data) => {
                  setOrderDetails((curr) => ({
                    ...curr,
                    items: [...curr.items, data],
                  }));
                  setUpdated(true);
                  formRef.current.scrollIntoView({ behavior: "smooth" });
                }}
                onEdit={(data, index) => {
                  setOrderDetails((curr) => ({
                    ...curr,
                    items: [
                      ...curr.items.slice(0, index),
                      data,
                      ...curr.items.slice(index + 1),
                    ],
                  }));
                  setUpdated(true);
                  formRef.current.scrollIntoView({ behavior: "smooth" });
                }}
                onDelete={(index) => {
                  setOrderDetails((curr) => ({
                    ...curr,
                    items: [
                      ...curr.items.slice(0, index),
                      ...curr.items.slice(index + 1),
                    ],
                  }));
                  setUpdated(true);
                  formRef.current.scrollIntoView({ behavior: "smooth" });
                }}
                subtotal={subtotal.toFixed(0)}
              />
            }
          />
        </section>
        <Modal open={showModal}>
          <div className={styles.modalContainer}>
            <div className={styles.modalTopRow}>
              <div className={styles.modalHeading}>Update Order</div>
              <div>
                <Close
                  onClick={() => setShowModal(false)}
                  style={{ cursor: "pointer" }}
                />
              </div>
            </div>
            <div className={styles.dateContainer}>
              <DatePicker
                id="update-order-date"
                label="Delivery Date"
                value={orderDetails.date}
                onChange={(e) =>
                  setOrderDetails((curr) => ({ ...curr, date: e.target.value }))
                }
                min={orderDetails.date}
                max={dayjs().add(1, "month").format("YYYY-MM-DD")}
              />
            </div>
            <div className={styles.modalActionContainer}>
              <PrimaryButton
                label="Update Order"
                type="button"
                loading={state === "loading" || !orderDetails.date}
                ref={submitRef}
                onClick={addVersion}
              />
            </div>
          </div>
        </Modal>
      </Form>
    </div>
  );
}
