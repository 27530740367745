import { useFetcher, useLoaderData } from "react-router-dom";
import { generateGetLoader, getInvoiceStatus } from "../../../lib/helpers";
import BreadCrums from "../../../components/breadcrums";
import styles from "./details.module.css";
import Control from "../../../components/control";
import dayjs from "dayjs";
import Table from "../../../components/table";
import PrimaryButton from "../../../components/primary-button";
import { createPostRequest } from "../../../lib/network";
import DropdownButton from "./dropdown-button";

export async function loader({ params }) {
  const invoiceId = params.id;
  const response = await generateGetLoader(
    `${process.env.REACT_APP_BASE_URL}/api/invoice/admin/getOne/${invoiceId}`,
    "invoice",
  )();
  return response;
}

export async function action({ request, params }) {
  const formData = await request.formData();
  const action = formData.get("action");
  if (action === "approve") {
    await createPostRequest(
      `${process.env.REACT_APP_BASE_URL}/api/invoice/admin/approve/${params.id}`,
    );
  } else {
    await createPostRequest(
      `${process.env.REACT_APP_BASE_URL}/api/invoice/admin/status`,
      { InvoiceID: params.id, newStatus: formData.get("status") },
    );
  }
  return null;
}

export default function InvoiceDetails() {
  const { invoice } = useLoaderData();
  const fetcher = useFetcher();

  const { text, colour } = getInvoiceStatus(invoice);
  let inputClassName;
  // eslint-disable-next-line default-case
  switch (colour) {
    case "var(--success)":
      inputClassName = styles.success;
      break;
    case "var(--error)":
      inputClassName = styles.error;
      break;
    case "var(--info)":
      inputClassName = styles.info;
  }

  let subtotal = 0;
  const items = invoice.Invoice_Items.map((item) => {
    subtotal += item.Total_Item_Price;
    return [
      {
        value: item.Branch_Item_Object.Branch_Item.Item_Supplier.Supplier_Name,
        vAlign: "top",
      },
      {
        value: item.Branch_Item_Object.Branch_ID.Branch_Name,
        vAlign: "top",
      },
      {
        value: `${item.Branch_Item_Object.Branch_Item.Item_Name} - ${item.Variant_Object.Variant_Size}`,
        vAlign: "top",
      },
      {
        value:
          !item.Cutting_List || item.Cutting_List.length === 0 ? (
            ""
          ) : (
            <div className={styles.list}>
              {item.Cutting_List.map((record) => (
                <div>
                  {record.Size} {item.Variant_Object.Variant_Size_Type_Units}
                </div>
              ))}
            </div>
          ),
      },
      {
        value: item.Variant_Object.Variant_Colour?.Colour_Name || "",
        vAlign: "top",
      },
      {
        value:
          !item.Cutting_List || item.Cutting_List.length === 0 ? (
            item.Quantity
          ) : (
            <div className={styles.list}>
              {item.Cutting_List.map((record) => (
                <div>{record.Quantity}</div>
              ))}
            </div>
          ),
        align: "center",
      },
      {
        value: `$${item.Variant_Object.Variant_Selling_Price}`,
        vAlign: "top",
        align: "center",
      },
      {
        value: `$${item.Total_Item_Price}`,
        vAlign: "top",
        align: "center",
      },
    ];
  });

  return (
    <div>
      <BreadCrums
        segments={[
          { label: "Invoices", link: "../invoices" },
          { label: `Invoice #${invoice.Invoice_Number}`, link: "." },
        ]}
      />
      {invoice.Invoice_Action_Required && (
        <div className={styles.banner}>
          <div className={styles.bannerText}>
            The invoice is{" "}
            {invoice.Invoice_Amount_Outstanding > 0 ? "Pending" : "Overpaid"} by
            ${Math.abs(invoice.Invoice_Amount_Outstanding)}{" "}
          </div>
          <PrimaryButton
            label={
              invoice.Invoice_Amount_Outstanding > 0
                ? "Send Invoice"
                : "Issue Refund"
            }
            loading={fetcher.state === "submitting"}
            rounded
            onClick={() =>
              fetcher.submit({ action: "approve" }, { method: "POST" })
            }
          />
        </div>
      )}
      <div className={styles.row}>
        <Control
          label="Customer Name"
          id="customer-name"
          name="Customer_Name"
          editable={false}
          fullWidth
          initialValue={
            invoice.Invoice_Customer.Customer.Customer_Business_Name ||
            invoice.Invoice_Customer.Customer.Customer_Name
          }
        />
        <Control
          label="Issue Date"
          id="issue-date"
          name="Issue_Date"
          editable={false}
          fullWidth
          initialValue={dayjs(invoice.Invoice_Date).format("DD/MM/YYYY")}
        />
        <Control
          label="Customer ABN"
          id="customer-abn"
          name="Customer_ABN"
          editable={false}
          fullWidth
          initialValue={
            invoice.Invoice_Customer.Customer.Customer_Business_ABN ||
            "Not Applicable"
          }
        />
        <Control
          label="Shipping Address"
          id="shipping-address"
          name="Shipping_Address"
          editable={false}
          fullWidth
          initialValue={invoice.Invoice_Delivery_Address}
          smallFont
        />
        <Control
          label="Due Date"
          id="due-date"
          name="Due_Date"
          editable={false}
          fullWidth
          initialValue={dayjs(invoice.Invoice_Due_Date).format("DD/MM/YYYY")}
        />
        <Control
          label="Status"
          id="status"
          name="Status"
          editable={false}
          fullWidth
          initialValue={text}
          inputClassName={inputClassName}
        />
      </div>
      <Table
        headers={[
          { name: "Supplier", width: "12.3%" },
          { name: "Branch", width: "12%" },
          { name: "Item", width: "19%" },
          { name: "Size", width: "12.4%" },
          { name: "Colour", width: "14.7%" },
          { name: "QTY", width: "8.1%" },
          { name: "PPU", width: "6.5%" },
          { name: "Item Total", width: "15%" },
        ]}
        rows={items}
        content={
          <div>
            <div className={styles.bottomSection}>
              <div>
                {invoice.Invoice_Payments &&
                  invoice.Invoice_Payments.length > 0 && (
                    <>
                      <div className={styles.paymentsHeader}>Payments</div>
                      <table className={styles.paymentsTable}>
                        <thead>
                          <tr>
                            <th align="left">Amount</th>
                            <th align="left">Date</th>
                            <th align="left">Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          {invoice.Invoice_Payments.map((payment) => (
                            <tr key={payment._id}>
                              <td
                                style={{
                                  color:
                                    payment.Payment_Amount > 0
                                      ? "var(--success)"
                                      : "var(--error)",
                                }}
                              >
                                {payment.Payment_Amount > 0
                                  ? `+\$${payment.Payment_Amount}`
                                  : `\$${payment.Payment_Amount}`}
                              </td>
                              <td>
                                {dayjs(payment.Payment_Date).format(
                                  "DD.MM.YYYY",
                                )}
                              </td>
                              <td className={styles.status}>
                                {payment.Payment_Status.toLowerCase()}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </>
                  )}
              </div>
              <div>
                <table cellSpacing={0}>
                  <tbody>
                    <tr>
                      <td
                        className={styles.paymentDivision}
                        style={{
                          paddingBottom: "calc(1.16 * var(--base-spacing))",
                        }}
                      >
                        SUBTOTAL
                      </td>
                      <td
                        className={styles.paymentValue}
                        align="right"
                        style={{
                          paddingBottom: "calc(1.16 * var(--base-spacing))",
                        }}
                      >
                        ${subtotal.toFixed(0)}
                      </td>
                    </tr>
                    <tr>
                      <td className={styles.paymentDivision}>SHIPPING</td>
                      <td className={styles.paymentValue} align="right">
                        ${invoice.Invoice_Delivery_Price || 0}
                      </td>
                    </tr>
                    <tr>
                      <td className={styles.total}>TOTAL (incl. GST)</td>
                      <td className={styles.totalValue} align="right">
                        ${invoice.Invoice_Total_Price_GST || 0}
                      </td>
                    </tr>
                    <tr>
                      <td
                        className={styles.large}
                        style={{
                          paddingRight: "calc(4 * var(--base-spacing))",
                          paddingTop: "calc(2 * var(--base-spacing))",
                          paddingBottom: "calc(0.75 * var(--base-spacing))",
                        }}
                      >
                        TOTAL PAID
                      </td>
                      <td
                        align="right"
                        className={styles.large}
                        style={{
                          paddingLeft: "calc(4 * var(--base-spacing))",
                          paddingTop: "calc(2 * var(--base-spacing))",
                          paddingBottom: "calc(0.75 * var(--base-spacing))",
                        }}
                      >
                        ${invoice.Invoice_Amount_Paid || 0}
                      </td>
                    </tr>
                    <tr>
                      <td className={styles.large}>TOTAL DUE</td>
                      <td align="right" className={styles.large}>
                        ${invoice.Invoice_Amount_Outstanding || 0}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className={styles.actionContainer}>
              <DropdownButton
                update={(status) =>
                  fetcher.submit(
                    {
                      action: "update",
                      status,
                    },
                    { method: "POST" },
                  )
                }
                disabled={fetcher.state === "submitting"}
              />
            </div>
          </div>
        }
      />
    </div>
  );
}
