import React from "react";
import Modal from "../../../../components/modal";
import styles from "./cutting-list.module.css";
import { ReactComponent as Close } from "../../../../assets/icons/close.svg";
import Quantity from "../quantity";

export default function CuttingList({
  initialList,
  showModal,
  toggleModal,
  itemName,
  variantSize,
  unit,
  price,
  factor,
  updateCuttingList,
  maxSize,
  minSize,
}) {
  const [cuttingList, setCuttingList] = React.useState(initialList);
  const [cuttingErrors, setCuttingErrors] = React.useState({});

  function verifyCutting() {
    if (cuttingList.length === 0) {
      alert("Cutting list cannot be empty");
      return;
    }
    const errors = [];
    const newList = [];
    cuttingList.forEach((element, index) => {
      const size = parseInt(element.Size);
      if (isNaN(size) || size > maxSize || size < minSize) {
        if (index !== cuttingList.length - 1 || element.Size !== "") {
          errors.push(index);
        }
      } else {
        newList.push({
          Quantity: element.Quantity,
          Size: size,
          Total_Size_Price: parseFloat(
            ((element.Quantity * size * price) / factor).toFixed(2),
          ),
        });
      }
    });

    if (errors.length > 0) {
      setCuttingErrors(
        errors.reduce((acc, curr) => ({ ...acc, [curr]: true }), {}),
      );
    } else {
      toggleModal();
      updateCuttingList(newList);
    }
  }

  return (
    <Modal open={showModal}>
      <div className={styles.modalContainer}>
        <div className={styles.modalTopRow}>
          <div className={styles.modalHeading}>Cutting List</div>
          <div style={{ cursor: "pointer" }}>
            <Close
              onClick={(e) => {
                e.stopPropagation();
                toggleModal();
                setCuttingList(initialList);
              }}
            />
          </div>
        </div>
        <div className={styles.modalItem}>
          {itemName} {variantSize}
        </div>
        {Object.keys(cuttingErrors).length > 0 && (
          <div className={styles.errorText}>
            Size should be between {minSize} and {maxSize}
          </div>
        )}
        <div className={styles.modalTableContainer}>
          <table className={styles.modalTable}>
            <colgroup>
              <col style={{ width: "33%" }} />
              <col style={{ width: "9%" }} />
              <col style={{ width: "33%" }} />
              <col style={{ width: "25%" }} />
            </colgroup>
            <thead>
              <tr>
                <th align="left">Quantity</th>
                <th></th>
                <th align="left">Size</th>
                <th align="left">Total</th>
              </tr>
            </thead>
            <tbody>
              {cuttingList.map((record, index) => (
                <tr key={index.toString()}>
                  <td>
                    <Quantity
                      value={record.Quantity}
                      onChange={(newValue) =>
                        newValue > 0
                          ? setCuttingList((curr) => [
                              ...curr.slice(0, index),
                              { ...curr[index], Quantity: newValue },
                              ...curr.slice(index + 1),
                            ])
                          : setCuttingList((curr) => [
                              ...curr.slice(0, index),
                              ...curr.slice(index + 1),
                            ])
                      }
                    />
                  </td>
                  <td align="center">x</td>
                  <td>
                    <input
                      inputMode="numeric"
                      value={record.Size}
                      onChange={(e) => {
                        setCuttingList((curr) => [
                          ...curr.slice(0, index),
                          { ...curr[index], Size: e.target.value },
                          ...curr.slice(index + 1),
                        ]);
                        setCuttingErrors((curr) => {
                          const newErr = { ...curr };
                          delete newErr[index];
                          return newErr;
                        });
                      }}
                      className={`${styles.modalInput} ${
                        cuttingErrors[index] ? styles.modalInputError : ""
                      }`}
                      placeholder={unit}
                    />
                  </td>
                  <td>
                    <span className={styles.modalItemPrice}>
                      $
                      {(
                        (record.Quantity *
                          parseInt(record.Size || "0") *
                          price) /
                        factor
                      ).toFixed(2)}
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className={styles.addButtonContainer}>
          <button
            className={styles.addButton}
            onClick={() => {
              setCuttingList((curr) => [
                ...curr,
                { Size: "", Quantity: 1, Total_Size_Price: 0 },
              ]);
            }}
          >
            Add New Size
          </button>
        </div>
        <div className={styles.modalActionsContainer}>
          <button className={styles.modalSave} onClick={verifyCutting}>
            Save List
          </button>
          <button
            className={styles.modalDiscard}
            onClick={(e) => {
              e.stopPropagation();
              toggleModal();
              setCuttingList(initialList);
            }}
          >
            Discard Changes
          </button>
          <div
            role="button"
            className={styles.delete}
            onClick={() => setCuttingList([])}
          >
            Delete List
          </div>
        </div>
      </div>
    </Modal>
  );
}
