import React from "react";
import Control from "../../../../components/control";
import { debounce } from "lodash";
import styles from "./autocomplete.module.css";
import { createGetRequest } from "../../../../lib/network";

export default function Autocomplete({
  id,
  label,
  fullWidth,
  name,
  required,
  initialValue,
  callback,
  location,
  supplier,
  disabled,
}) {
  const [search, setSearch] = React.useState(initialValue || "");
  const [suggestions, setSuggestions] = React.useState([]);
  const [open, setOpen] = React.useState(true);
  const inputRef = React.useRef(null);

  React.useEffect(() => {
    function closeDropdown(e) {
      const evtTarget = e.target;
      if (evtTarget !== inputRef.current) {
        setOpen(false);
      }
    }
    document.addEventListener("click", closeDropdown);
    return () => document.removeEventListener("click", closeDropdown);
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearch = React.useCallback(
    debounce(async (newValue) => {
      const response = await createGetRequest(
        `${process.env.REACT_APP_BASE_URL}/api/item/getManyItems?limit=10&location=${location}&supplier=${supplier}&q=${newValue}`,
      );
      if (!response.data) {
        alert(response.message);
      } else {
        if (response.data.items.length > 0) {
          setSuggestions(
            response.data.items[0].items.map((record) => ({
              id: record._id,
              label: record.Name,
            })),
          );
        }
      }
    }, 200),
    [],
  );

  function handleSearchChange(newValue) {
    setSearch(newValue);
    setOpen(true);
    debouncedSearch(newValue);
  }

  function handleOptionClick(suggestion, e) {
    setSearch(suggestion.label);
    setOpen(false);
    e.currentTarget.blur();
    if (callback) {
      callback(suggestion.id);
    }
  }

  return (
    <div className={`${styles.container} ${open ? styles.active : ""}`}>
      <Control
        id={id}
        label={label}
        name={name}
        required={required}
        fullWidth={fullWidth}
        value={search}
        onChange={handleSearchChange}
        ref={inputRef}
        smallFont
        disabled={disabled}
      />
      <ul className={styles.list}>
        {suggestions.map((suggestion) => (
          <li key={suggestion.id}>
            <input
              type="radio"
              name={name}
              id={suggestion.id}
              required={required}
              value={suggestion.id}
              onClick={(e) => handleOptionClick(suggestion, e)}
              autoComplete="off"
            />
            <label htmlFor={suggestion.id}>
              <div className={styles.name}>{suggestion.label}</div>
            </label>
          </li>
        ))}
      </ul>
    </div>
  );
}
