import * as React from "react";
import {
  Form,
  useLoaderData,
  redirect,
  useBeforeUnload,
  useNavigation,
} from "react-router-dom";
import ItemDetail from "../item-detail";
import Variant from "../variant";
import styles from "./create-item.module.css";
import PrimaryButton from "../../../components/primary-button";
import { DATA_KEY, VARIANT_ID_KEYS } from "../../constants";
import { generateGetLoader } from "../../../lib/helpers";
import { formatFormData } from "../../helpers";
import { createPostRequest, uploadImage } from "../../../lib/network";
import Modal from "../../../components/modal";
import { v4 as uuidv4 } from "uuid";
import BreadCrums from "../../../components/breadcrums";
import CustomLink from "../../../components/custom-link";
import { ReactComponent as IconAdd } from "../../../assets/icons/Add.svg";
import Branches from "../branches";

export async function loader() {
  const data = await generateGetLoader(
    [
      `${process.env.REACT_APP_BASE_URL}/api/industry/getMany`,
      `${process.env.REACT_APP_BASE_URL}/api/supplier/getAll`,
      `${process.env.REACT_APP_BASE_URL}/api/colour/getAll`,
      `${process.env.REACT_APP_BASE_URL}/api/colourCategory/getMany`,
    ],
    ["industries", "suppliers", "colours", "categories"],
  )();
  const storedForm = localStorage.getItem(DATA_KEY);
  if (storedForm) {
    const parsedForm = JSON.parse(storedForm);
    data.form = { data: parsedForm.data, step: parsedForm.step };
  }
  return data;
}

export async function action({ request }) {
  const formData = await request.formData();
  const formattedData = formatFormData(formData);
  delete formattedData.item.Default_Branch;
  const requestBody = {
    Items: [
      {
        ...formattedData.item,
        Variants: [],
        branchMapping: [],
      },
    ],
  };
  Object.values(formattedData.variants).forEach((data) => {
    const variantsForSize = [];
    const branchItemsForSize = [];
    delete data.Variant_Colour_Type;
    if (data.Variant_Colour && Object.keys(data.Variant_Colour).length > 0) {
      Object.values(data.Variant_Colour).forEach((record) => {
        record.colours.forEach((colour) => {
          const obj = {
            ...data,
            Variant_Colour: colour.split("#")[0],
            Variant_Selling_Price: record.price,
            Variant_Cost_Price: record.costPrice,
            Variant_Client_ID: uuidv4(),
          };
          if (obj.Variant_Size_Min) {
            obj.Variant_Size_Min = parseFloat(obj.Variant_Size_Min);
          }
          if (obj.Variant_Size_Max) {
            obj.Variant_Size_Max = parseFloat(obj.Variant_Size_Max);
          }
          if (obj.Variant_Size_Type_Option) {
            obj.Variant_Size_Type_Option =
              obj.Variant_Size_Type_Option.split("#")[0];
          }
          if (obj.Variant_Size_Type_Units) {
            obj.Variant_Size_Type_Units =
              obj.Variant_Size_Type_Units.split("#")[0];
          }
          variantsForSize.push(obj);
        });
      });
    }
    const obj = {
      ...data,
      Variant_Colour: null,
      Variant_Client_ID: uuidv4(),
    };
    if (obj.Variant_Size_Min) {
      obj.Variant_Size_Min = parseFloat(obj.Variant_Size_Min);
    }
    if (obj.Variant_Size_Max) {
      obj.Variant_Size_Max = parseFloat(obj.Variant_Size_Max);
    }
    if (obj.Variant_Size_Type_Option) {
      obj.Variant_Size_Type_Option = obj.Variant_Size_Type_Option.split("#")[0];
    }
    if (obj.Variant_Size_Type_Units) {
      obj.Variant_Size_Type_Units = obj.Variant_Size_Type_Units.split("#")[0];
    }
    variantsForSize.push(obj);

    variantsForSize.forEach((variant) => {
      Object.entries(formattedData.branches).forEach(
        ([branchId, branchData]) => {
          branchItemsForSize.push({
            Branch_ID: branchId,
            Variant_Client_ID: variant.Variant_Client_ID,
            Variant_Cost_Price: parseFloat(
              (
                (1 + parseFloat(branchData.Branch_Cost_Price || "0") * 0.01) *
                parseFloat(variant.Variant_Cost_Price)
              ).toFixed(2),
            ),
            Variant_Selling_Price: parseFloat(
              (
                (1 +
                  parseFloat(branchData.Branch_Selling_Price || "0") * 0.01) *
                parseFloat(variant.Variant_Selling_Price)
              ).toFixed(2),
            ),
            Variant_Delivery_Charges: parseFloat(
              branchData.Branch_Delivery_Cost,
            ),
            Variant_Estimated_Delivery_Time: parseFloat(branchData.Branch_EDT),
          });
        },
      );
      delete variant.Variant_Cost_Price;
      delete variant.Variant_Selling_Price;
    });
    requestBody.Items[0].Variants.push(...variantsForSize);
    requestBody.Items[0].branchMapping.push(...branchItemsForSize);
  });
  const image = formData.get("Item_Image");
  if (image instanceof Blob && image.size > 0) {
    const response = await uploadImage(image);
    requestBody.Items[0].Item_Image = response.imageName;
  }
  const imageLarge = formData.get("Item_Image_Large");
  if (imageLarge instanceof Blob && imageLarge.size > 0) {
    const response = await uploadImage(imageLarge);
    requestBody.Items[0].Item_Image_Large = response.imageName;
  }
  const URL = `${process.env.REACT_APP_BASE_URL}/api/branchItems/create`;
  await createPostRequest(URL, requestBody);
  localStorage.removeItem(DATA_KEY);
  localStorage.removeItem(VARIANT_ID_KEYS);
  return redirect("/user/items");
}

export default function CreateItem() {
  const {
    industries,
    suppliers,
    colours,
    categories,
    form: loadedForm,
  } = useLoaderData();
  const storedVariantIds = localStorage.getItem(VARIANT_ID_KEYS);
  const [variantIds, setVariantIds] = React.useState(
    storedVariantIds ? JSON.parse(storedVariantIds) : [uuidv4()],
  );
  const [form, setForm] = React.useState(loadedForm?.data);
  const [step, setStep] = React.useState(loadedForm?.step || 1);
  const [branches, setBranches] = React.useState(
    loadedForm?.data?.item?.Item_Supplier
      ? () => {
          const newSup = suppliers.find(
            (item) => item._id === loadedForm?.data?.item?.Item_Supplier,
          );
          return newSup.Branches;
        }
      : [],
  );
  const [modalOpen, setModalOpen] = React.useState(!!loadedForm?.data);
  const { state } = useNavigation();
  const formRef = React.useRef();
  const submitButtonRef = React.useRef();
  const beforeUnload = React.useCallback(
    function () {
      if (!formRef.current) {
        return;
      }
      const formData = new FormData(formRef.current);
      const formattedData = formatFormData(formData);
      localStorage.setItem(
        DATA_KEY,
        JSON.stringify({ data: formattedData, step: step }),
      );
      localStorage.setItem(VARIANT_ID_KEYS, JSON.stringify(variantIds));
    },
    [formRef, variantIds, step],
  );

  useBeforeUnload(beforeUnload);
  // useLeavePage(beforeUnload);

  function handleModalCancel() {
    setForm(null);
    localStorage.removeItem(DATA_KEY);
    localStorage.removeItem(VARIANT_ID_KEYS);
    setVariantIds([uuidv4()]);
    setModalOpen(false);
    setStep(1);
    setBranches([]);
  }

  function handleModalAccept() {
    setModalOpen(false);
  }

  function addVariant(e) {
    e.preventDefault();
    setVariantIds((curr) => [...curr, uuidv4()]);
  }

  function handleSubmit() {
    submitButtonRef.current.setAttribute("disabled", "true");
    const formData = new FormData(formRef.current);
    const formattedData = formatFormData(formData);
    let isValid = true;

    if (Object.keys(formattedData.variants).length === 0) {
      document
        .getElementById(`variant-size-${variantIds[0]}`)
        .setCustomValidity("Please fill all sizes information");
      isValid = false;
    } else {
      document
        .getElementById(`variant-size-${variantIds[0]}`)
        .setCustomValidity("");
    }

    Object.entries(formattedData.variants).forEach((variantId, data) => {
      if (data.Variant_Colour) {
        Object.entries(data.Variant_Colour).forEach(([category, catData]) => {
          if (!catData.colours || catData.colours.length === 0) {
            document
              .querySelector(`[name="Variant_Colour_${category}-${variantId}"]`)
              .setCustomValidity("Please select at least one colour");
            isValid = false;
          } else {
            document
              .querySelector(`[name="Variant_Colour_${category}-${variantId}"]`)
              .setCustomValidity("");
          }
        });
      }
    });
    submitButtonRef.current.removeAttribute("disabled");
    if (!isValid) {
      formRef.current.reportValidity();
      return;
    }
    formRef.current.requestSubmit();
  }

  function handleDelete(id) {
    setVariantIds((curr) => {
      if (curr.length === 1) {
        return curr;
      }
      return curr.filter((varId) => varId !== id);
    });
  }

  function updateBranches(newSupplier) {
    const newSup = suppliers.find((item) => item._id === newSupplier);
    setBranches(newSup.Branches);
  }

  function moveToVariants() {
    const formData = new FormData(formRef.current);
    const formattedData = formatFormData(formData);
    let isValid = true;
    if (Object.keys(formattedData.branches).length < branches.length) {
      const defaultBranchId = formattedData.item.Default_Branch;
      document
        .getElementById(`default-branch-${defaultBranchId}`)
        .setCustomValidity("Please fill all branches information");
      isValid = false;
    } else {
      const defaultBranchId = formattedData.item.Default_Branch;
      document
        .getElementById(`default-branch-${defaultBranchId}`)
        .setCustomValidity("");
    }

    Object.entries(formattedData.branches).forEach(([branchId, data]) => {
      if (branchId !== formattedData.item.Default_Branch) {
        if (!data.Branch_Cost_Price) {
          document
            .getElementById(`branch-${branchId}-price`)
            .setCustomValidity("Cost price is required for non default branch");
          isValid = false;
        } else {
          document
            .getElementById(`branch-${branchId}-price`)
            .setCustomValidity("");
        }
        if (!data.Branch_Selling_Price) {
          document
            .getElementById(`branch-${branchId}-selling-price`)
            .setCustomValidity(
              "Selling price is required for non default branch",
            );
          isValid = false;
        } else {
          document
            .getElementById(`branch-${branchId}-selling-price`)
            .setCustomValidity("");
        }
      }

      if (!data.Branch_Delivery_Cost) {
        document
          .getElementById(`branch-${branchId}-delivery`)
          .setCustomValidity("Delivery cost is required");
        isValid = false;
      } else {
        document
          .getElementById(`branch-${branchId}-delivery`)
          .setCustomValidity("");
      }

      if (!data.Branch_EDT) {
        document
          .getElementById(`branch-${branchId}-edt`)
          .setCustomValidity("ETD is required");
        isValid = false;
      } else {
        document.getElementById(`branch-${branchId}-edt`).setCustomValidity("");
      }
    });

    if (!isValid) {
      formRef.current.reportValidity();
      return;
    }

    setStep(2);
  }

  return modalOpen ? (
    <Modal open>
      <div className={styles.modalContainer}>
        <div className={styles.modalPrompt}>
          Would you edit the last saved draft?
        </div>
        <div className={styles.modalActionContainer}>
          <PrimaryButton
            variant="outlined"
            label="No"
            type="button"
            onClick={handleModalCancel}
          />
          <PrimaryButton
            label="Yes"
            type="button"
            onClick={handleModalAccept}
          />
        </div>
      </div>
    </Modal>
  ) : (
    <>
      <BreadCrums
        segments={[
          { label: "Items", link: "../items" },
          { label: "Create Item", link: "." },
        ]}
      />
      <div className={styles.headingContainer}>
        <div className={styles.heading}>Create Item</div>
        <CustomLink text="Create Item" icon={<IconAdd />} href="." />
      </div>
      <Form
        className={styles.container}
        encType="multipart/form-data"
        method="post"
        ref={formRef}
      >
        <ItemDetail
          industries={industries}
          suppliers={suppliers}
          prevData={form?.item}
          onSupplierChange={updateBranches}
        />
        <div className={styles.tabsContainer}>
          <div
            className={`${styles.tab} ${step === 1 ? styles.activeTab : ""}`}
            onClick={() => setStep(1)}
          >
            Branches
          </div>
          <div
            className={`${styles.tab} ${step === 2 ? styles.activeTab : ""} ${
              styles.noClick
            }`}
          >
            Sizes
          </div>
        </div>
        <section className={step === 1 ? styles.visible : styles.hidden}>
          <Branches
            branches={branches}
            onNext={moveToVariants}
            prevData={form?.branches}
            prevDefaultBranch={form?.item?.Default_Branch}
          />
        </section>
        <section className={step === 2 ? styles.visible : styles.hidden}>
          {variantIds.map((value, index) => (
            <Variant
              count={index + 1}
              key={value}
              id={value}
              prevData={form?.variants ? form.variants[value] : undefined}
              onDelete={() => handleDelete(value)}
              colours={colours}
              categories={categories.colourCategories}
            />
          ))}
          <div className={styles.actionContainer}>
            <div>
              <PrimaryButton
                label="Add Variant"
                type="button"
                variant="outlined"
                onClick={addVariant}
              />
            </div>
            <PrimaryButton
              label="Create Item"
              type="button"
              onClick={handleSubmit}
              loading={state === "submitting"}
              ref={submitButtonRef}
            />
          </div>
        </section>
      </Form>
    </>
  );
}
