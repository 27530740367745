import { redirect } from "react-router-dom";
import { createPostRequest } from "../../../lib/network";

export async function action({ request }) {
  const formData = await request.formData();
  const orderID = formData.get("orderID");
  if (orderID) {
    const supplierID = formData.get("supplierID");
    await createPostRequest(
      `${process.env.REACT_APP_BASE_URL}/api/purchaseOrder/createFromOrder`,
      { orderID, supplierID },
    );
    return null;
  }
  return redirect("/user/purchaseOrders");
}
