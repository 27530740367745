import { useLoaderData, useNavigate } from "react-router-dom";
import PageHeader from "../components/page-header";
import Table from "../components/table";

export default function Suppliers() {
  const { suppliers } = useLoaderData();
  const navigate = useNavigate();
  const supplierRows = suppliers.map((supplier) => [
    {
      value: `${supplier._id}`,
    },
    { value: supplier.Supplier_Name },
    { value: supplier.Supplier_Email },
    { value: supplier.Supplier_Phone },
    {
      value: (
        <div
          title={supplier.Supplier_Notes}
          style={{
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
          }}
        >
          {supplier.Supplier_Notes}
        </div>
      ),
    },
  ]);

  function handleRowClick(id) {
    navigate(id);
  }

  return (
    <>
      <PageHeader text="Suppliers" />
      <Table
        headers={[
          { name: "Name", width: "17%" },
          { name: "Email", width: "23%" },
          { name: "Phone", width: "23%" },
          { name: "Note", width: "37%" },
        ]}
        rows={supplierRows}
        onRowClick={handleRowClick}
        bodyStyles={{ height: "calc(100vh - 350px" }}
      />
    </>
  );
}
