import { useLoaderData } from "react-router-dom";
import { generateGetLoader } from "../../../lib/helpers";
import BreadCrums from "../../../components/breadcrums";
import Control from "../../../components/control";
import dayjs from "dayjs";
import styles from "./details.module.css";

export async function loader({ params }) {
  const response = await generateGetLoader(
    `${process.env.REACT_APP_BASE_URL}/api/purchaseOrder/getOne/${params.id}`,
    "po",
  )();
  return response;
}

export default function PurchaseOrderDetails() {
  const { po } = useLoaderData();

  return (
    <div>
      <BreadCrums
        segments={[
          { label: "Purchase Orders", link: "../purchase-orders" },
          { label: `Puchase Order #${po.PurchaseOrder_Number}`, link: "." },
        ]}
      />
      <div className={styles.topSection}>
        <Control
          label="Supplier"
          id="supplier"
          name="Supplier"
          editable={false}
          fullWidth
          initialValue={po.PurchaseOrder_Supplier.Supplier_Name}
        />
        <Control
          label="Issue Date"
          id="issue-date"
          name="Issue_Date"
          editable={false}
          fullWidth
          initialValue={dayjs(po.PurchaseOrde_Date).format("DD/MM/YYYY")}
        />
        <Control
          label="Delivery Type"
          id="delivery-type"
          name="Delivery_Type"
          editable={false}
          fullWidth
          initialValue={
            po.PurchaseOrder_Delivery_Type === "Delivery"
              ? "To Site"
              : po.PurchaseOrder_Delivery_Address ===
                "6/56 Montague St, North Wollongong, NSW"
              ? "To Pova"
              : "Pick Up"
          }
        />
        <Control
          label="Supplier Branch"
          id="supplier-branch"
          name="Supplier_Branch"
          editable={false}
          fullWidth
          initialValue={po.PurchaseOrder_Supplier_Branch.Branch_Name}
          smallFont
        />
        <Control
          label="Shipping Address"
          id="shipping-address"
          name="Shipping_Address"
          editable={false}
          fullWidth
          initialValue={po.PurchaseOrder_Delivery_Address}
          smallFont
        />
        <Control
          label="Status"
          id="status"
          name="Status"
          editable={false}
          fullWidth
          initialValue={po.PurchaseOrder_Status}
        />
      </div>
    </div>
  );
}
